import { makeAutoObservable } from 'mobx';
import { State } from '../enum/state';
import http from '../services/http';
import { message } from 'antd';
import { IResultDto } from '../models/ResultDto';

export default class DrawingStore {
  item?: IObject;

  isLoading = false;

  state: State = 'None';

  async onCreate(values: any) {
    this.isLoading = true;
    try {
      const result = await http.post('/drawings', values);
      const value = result.data as IResultDto<IObject>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onRead(id?: string) {
    if (!id) return;
    this.isLoading = true;
    try {
      const result = await http.get(`/drawings/${id}`);
      const value = result.data as IResultDto<IObject>;
      this.item = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onDelete(id: string) {
    this.isLoading = true;
    try {
      const result = await http.delete(`/drawings/${id}`);
      const value = result.data as IResultDto<any>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onUpdate(values: any, id?: string) {
    this.isLoading = true;
    try {
      const result = await http.put(`/drawings/${id}`, values);
      const value = result.data as IResultDto<IObject>;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

// Для каждого чертежа мы создаем отдельный обьект с привязкой к помещению

// Controller = Drawings
// // drawings
// POST // Создаем чертеж - При создании мы указываем тип и название (Если обьект с типом 'plan' уже есть, система должна слать 400 exception)

// // drawings?premiseId=4
// GET // Получаем список обьектов чертежей в помещении

// // drawings/:id
// GET // Получаем один чертеж

// // drawings/:id
// PUT // Обовляем один чертеж, передаем целый обьект

// // drawings/:id/apply-plan
// POST // Создаем чертеж из плана помещения. Из обьекта создается дубоикат, новый ID и там меняется на 'drawing'

// // drawings/:id
// DELETE // Удаляем чертеж

interface IObject {
  id: string;
  type: string; // 'plan' | 'drawing'
  name: string;
  area: number;
  perimeter: number;
  polygon: any; // JSON Value

  // premise: // MANY TO ONE
}
